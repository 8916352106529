import './style.css';
import ReactDOM from 'react-dom/client';
// import { VRButton, ARButton, XR, Controllers, Hands } from '@react-three/xr'
// import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';

const root = ReactDOM.createRoot(document.querySelector('#root'));

const App = () => {
  return (
    <div
      className='main'
    >
      <header
        className='header'
      >
        <img src='/logo.svg' alt='logo' className='logo' />
      </header>
      <p
        className='tagline'
      >
        Designing the Next Dimension of Experience.
      </p>
      <footer 
        className='footer'
      >
        <p>
          <a
            href='mailto:ryan@seen.link'
            className='footer-link'
          >Contact</a>
        </p>
      </footer>
    </div>
  );
}

root.render(<App />);